import Axios from '@/http'

export default {
  detail (deviceId) {
    return new Promise((resolve, reject) => {
      Axios.post('device/detail', { deviceId }).then(res => {
        res.data.onlineStateStr = res.data.onlineState === 1 ? '在线' : '离线'
        resolve(res)
      })
    })
  }
}
