import Axios from '@/http'

export default {
  // 燃气险情详情
  gasCaseDetail (gasCaseId) {
    return new Promise((resolve, reject) => {
      Axios.post('gasCase/detail', { gasCaseId }).then(res => {
        resolve(res)
      }).catch((res) => {
        reject(res)
      })
    })
  },
  // 报警人位置查询
  alarmPersonLocationFindAll () {
    return new Promise((resolve, reject) => {
      Axios.post('gasCase/alarmPersonLocationFindAll').then(res => {
        resolve(res)
      }).catch((res) => {
        reject(res)
      })
    })
  },
  // 报警人调整警情位置
  alarmPersonLocationUpdate (params) {
    return new Promise((resolve, reject) => {
      Axios.post('gasCase/alarmPersonLocationUpdate', params, { isJSON: true }).then(res => {
        resolve(res)
      }).catch((res) => {
        reject(res)
      })
    })
  },
  // 报警人保存个人信息
  alarmPersonLocationSave (params) {
    return new Promise((resolve, reject) => {
      Axios.post('gasCase/alarmPersonLocationSave', params, { isJSON: true }).then(res => {
        resolve(res)
      }).catch((res) => {
        reject(res)
      })
    })
  }
}
