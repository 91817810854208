let contentObj = {}

// const oriented = process.env.VUE_APP_BUILD_VERION
if (typeof window === 'object') {
  const routes = [
      {
        path: '*',
        redirect: '/'
      },
      {
        /* 领导端 */
        path: '/',
        name: 'videoMeeting',
        component: resolve => require(['../views/videoMeeting/videoMeeting'], resolve),
        meta: {
          title: '视频会议'
        }
      }
    ]
  contentObj = {
    routes
  }
}
const baseURL = '/gcleader'

module.exports = {
  ...contentObj,
  baseURL
}
