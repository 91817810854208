import Axios from '@/http'

export default {
  clientInfoByCaseId (params) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/clientInfoByCaseId', params).then(res => {
        resolve(res)
      })
    })
  },
  /* 获取小程序scheme码 */
  generateScheme (params) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/generateScheme', params).then(res => {
        resolve(res)
      })
    })
  },
  /* 报警人上报定位信息 */
  updateLocation (params) {
    return new Promise((resolve, reject) => {
      Axios.post('policeCase/updateLocation', params, { isJSON: false }).then(res => {
        resolve(res)
      })
    })
  }
}
