import Vue from 'vue'
import Router from 'vue-router'
// import store from '@store'
Vue.use(Router)
// const defaultRoute = []
const { routes } = require(`@/version/${process.env.VUE_APP_BUILD_VERION}-${process.env.VUE_APP_BUILD_ORIENTED}.js`)
// 解决跳相同路由报错
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const createRouter = () => new Router({
  mode: 'hash',
  routes: [...routes]
})
const router = createRouter()

router.beforeEach((to, from, next) => {
  document.getElementById('app').scrollTop = 0
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  next()
})
export default router
