/* 输入框相关校验方法 */
import Element from 'element-ui'
function doHandleMonth (month) {
  var m = month
  if (month.toString().length === 1) {
    m = '0' + month
  }
  return m
}
export default {
  install (Vue) {
    Vue.prototype.transformation = function (num) {
      let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
      let unit = ['', '十', '百', '千', '万']
      num = parseInt(num)
      let getWan = (temp) => {
        let strArr = temp.toString().split('').reverse()
        let newNum = ''
        let newArr = []
        const length= strArr.length
        strArr.forEach((item, index) => {
          const unitItem = unit[index]
          const changeNumItem = changeNum[item]
          if (changeNumItem === '一' && unitItem === '十' && length < 3) {
            newArr.unshift(item === '0' ? changeNumItem : unitItem)
          } else {
            newArr.unshift(item === '0' ? changeNumItem : changeNumItem + unitItem)
          }
        })
        let numArr = []
        newArr.forEach((m, n) => {
          if (m !== '零') numArr.push(n)
        })
        if (newArr.length > 1) {
          newArr.forEach((m, n) => {
            if (newArr[newArr.length - 1] === '零') {
              if (n <= numArr[numArr.length - 1]) {
                newNum += m
              }
            } else {
              newNum += m
            }
          })
        } else {
          newNum = newArr[0]
        }
        return newNum
      }
      let overWan = Math.floor(num / 10000)
      let noWan = num % 10000
      if (noWan.toString().length < 4) {
        noWan = '0' + noWan
      }
      return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
    }
    // 获取一年中的天数
    Vue.prototype.daysOfTheYear = function (t) {
      var Year = new Date().getFullYear()
      var s = 0; var d// 获取当前年
      if (!t) {
        for (let i = 1; i < 13; i++) {
          d = new Date(Year, i, 0)// 获取某一个月的天数
          s += d.getDate()
        }
        return s
      } else if (t >= 1970) {
        for (let i = 1; i < 13; i++) {
          d = new Date(t, i, 0)
          s += d.getDate()
        }
        return s
      }
    }
    Vue.prototype.getDay = function (day, type) {
      var today = new Date()
      today.setTime(today.getTime() + 1000 * 60 * 60 * 24 * day)
      var tYear = today.getFullYear()
      var tMonth = today.getMonth()
      var tDate = today.getDate()
      tMonth = doHandleMonth(tMonth + 1)
      tDate = doHandleMonth(tDate)
      if (type === 2) {
        return tYear + '/' + tMonth + '/' + tDate
      } else {
        return tYear + '-' + tMonth + '-' + tDate
      }
    }
    /* createUniqueId 生成随机字符串 */
    Vue.prototype.createUniqueId = function (n, type) {
      var random = function () {
        if (type === 2) {
          return Number(Math.random().toString().substr(2)).toString(36)
        } else {
          return Number(Math.random().toString().substr(2)).toString(36) + Number(Math.random().toString().substr(2)).toString(36) // 转换成十六进制
        }
      }
      var arr = []
      function createId () {
        var num = random()
        var _bool = false
        arr.forEach(v => {
          if (v === num) _bool = true
        })
        if (_bool) {
          createId()
        } else {
          arr.push(num)
        }
      }
      var i = 0
      while (i < n) {
        createId()
        i++
      }
      return arr[0]
    }
    /* enterNumbersRrLetters 只能输入数字和字母
        * compareName 不能大于 compareName
        * message 提示内容
    *  */
    Vue.prototype.enterNumbersRrLetters = function (row, name, content) {
      var replace = /[^\w]/g
      if (content === 1) {
        replace = /[^\da-z]/g
      }
      if (row[name] && row[name].length !== 0 && row[name] !== null) {
        row[name] += ''
        row[name] = row[name].replace(replace, '')
        row[name] = row[name].replace('.', '')
      }
    }
    /* enterTheMumber 只能输入数字
        * compareName 不能大于 compareName
        * message 提示内容
    *  */
    Vue.prototype.enterTheMumber = function (row, name, compareName, message) {
      if (row[name] && row[name].length !== 0 && row[name] !== null) {
        row[name] += ''
        // eslint-disable-next-line no-redeclare,no-useless-escape
        row[name] = row[name].replace(/[^\.\d]/g, '')
        row[name] = row[name].replace('.', '')
        if (compareName) {
          row[name] -= 0
          row[compareName] -= 0
          if (row[name] > row[compareName]) {
            Element.Message({
              message: message || '减的金额不能大于满的金额',
              type: 'error'
            })
            row[name] = row[compareName]
          }
        }
      }
    }
    /* inputEnglish 只能输入英文
    * compareName 不能大于 compareName
    * message 提示内容
*  */
    Vue.prototype.inputEnglish = function (row, name, compareName, message) {
      if (row[name] && row[name].length !== 0 && row[name] !== null) {
        row[name] += ''
        // eslint-disable-next-line no-redeclare,no-useless-escape
        row[name] = row[name].replace(/[^a-zA-Z]/g, '')
        row[name] = row[name].replace('.', '')
        if (compareName) {
          row[name] -= 0
          row[compareName] -= 0
          if (row[name] > row[compareName]) {
            Element.Message({
              message: message || '减的金额不能大于满的金额',
              type: 'error'
            })
            row[name] = row[compareName]
          }
        }
      }
    }
    /* keepTwoDecimalPlaces 保留两位小数 */
    Vue.prototype.keepTwoDecimalPlaces = function (numerical) {
      // eslint-disable-next-line no-redeclare
      if (numerical) {
        var value = Math.round(parseFloat(numerical) * 100) / 100
        var xsd = value.toString().split('.')
        if (xsd.length === 1) {
          value = value.toString() + '.00'
          return value
        }
        if (xsd.length > 1) {
          if (xsd[1].length < 2) {
            value = value.toString() + '0'
          }
          return value
        }
      } else {
        return '0.00'
      }
    }
    /* filterSpaces 过滤空格 */
    Vue.prototype.filterSpaces = function (row, name, content) {
      if (row[name] && row[name].length !== 0 && row[name] !== null) {
        row[name] += ''
        row[name] = row[name].replace(/[` ]/g, '')
        row[name] = row[name].replace(/\s/g, '')
      }
    }
    /* inputForbidden 禁止输入的内容 */
    Vue.prototype.inputForbidden = function (row, name, content) {
      var replace = /[`~!@#$%^&+=<>?:"{}|,./;'\\[\]·~！@#￥%……&+={}|《》？：“”【】、；‘’，。、]/g
      if (content === 1) {
        replace = /[`~!@#$%^&+=<>?:"{}|,/;'\\[\]·~！@#￥%……&+={}|《》？：“”【】、；‘’，。、]/g
      }
      if (row[name] && row[name].length && row[name] !== null) {
        row[name] += ''
        row[name] = row[name].replace(replace, '')
        row[name] = row[name].replace(/\s/g, '')
      }
    }
    /* 可以输入小数 */
    Vue.prototype.onlyNumberv2 = function (row, name, compareName, message) {
      if (row[name] && row[name].length && row[name] !== null) {
        row[name] += ''
        // 得到第一个字符是否为负号
        // var t = row[name].charAt(0)
        // 先把非数字的都替换掉，除了数字和.
        // eslint-disable-next-line no-redeclare,no-useless-escape
        row[name] = row[name].replace(/[^\d\.]/g, '')
        // 必须保证第一个为数字而不是.
        row[name] = row[name].replace(/^\./g, '')
        // 保证只有出现一个.而没有多个.
        row[name] = row[name].replace(/\.{2,}/g, '.')
        // 保证.只出现一次，而不能出现两次以上
        row[name] = row[name].replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
        if (compareName) {
          if (row[name] > compareName) {
            Element.Message({
              message: message || '减的金额不能大于满的金额',
              type: 'error'
            })
            row[name] = compareName
          }
        }
      }
    }
    /* 可以输入负数 和 小数 */
    Vue.prototype.onlyNumber = function (row, name, type) {
      if (row[name] && row[name].length && row[name] !== null) {
        row[name] += ''
        // 得到第一个字符是否为负号
        var t = row[name].charAt(0)
        // 先把非数字的都替换掉，除了数字和.
        // eslint-disable-next-line no-redeclare,no-useless-escape
        row[name] = row[name].replace(/[^\d\.]/g, '')
        // 必须保证第一个为数字而不是.
        row[name] = row[name].replace(/^\./g, '')
        // 保证只有出现一个.而没有多个.
        row[name] = row[name].replace(/\.{2,}/g, '.')
        // 保证.只出现一次，而不能出现两次以上
        row[name] = row[name].replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
        // 如果第一位是负号，则允许添加
        if (type !== 1) {
          if (t === '-') {
            row[name] = '-' + row[name]
          }
        }
      }
    }
    /* 文件大小转换 */
    Vue.prototype.publicFileSizeFn = function (size) {
      if (size) {
        if (size < 1024) {
          return size.toFixed(3) + 'B'
        }
        size /= 1024
        if (size < 1024) {
          return size.toFixed(3) + 'KB'
        }
        size /= 1024
        if (size < 1024) {
          return size.toFixed(3) + 'MB'
        }
        size /= 1024
        if (size < 1024) {
          return size.toFixed(3) + 'GB'
        }
        size /= 1024
        return size.toFixed(3) + 'TB'
      } else {
        return '/'
      }
    }
    /* 敏感字用***替换 */
    Vue.prototype.sensitiveFontsFn = function (name, number = 6, moveBack = 0) {
      if (name) {
        const str = name + ''
        var content = ''
        var replace = ''
        if (str.length < 6) number = 2
        for (let i = 0; i < number; i++) {
          replace += '*'
        }
        content = str.substr(0, parseInt(str.split('').length / number + moveBack)) + replace + str.substr(parseInt(str.split('').length / number + number), str.split('').length)
        return content
      } else {
        return name
      }
    }
    /* 敏感字用***替换 */
    Vue.prototype.sensitiveFontsListFn = function (IdList, type) {
      var content = ''
      if (type === 1) {
        if (IdList) {
          var arr = IdList.split('-')
          arr[0] = this.sensitiveFontsFn(arr[0])
          return arr.join('-')
        } else {
          return '/'
        }
      } else {
        if (IdList) {
          var arrV2 = IdList.split(',')
          arrV2.forEach(v => {
            content += this.sensitiveFontsFn(v) + ','
          })
          return content.substring(0, content.length - 1)
        } else {
          return '/'
        }
      }
    }
  }
}
