import Axios from '@/http'

export default {
  /* 退出登录 */
  logout () {
    return new Promise((resolve, reject) => {
      Axios.post('login/logout').then(res => {
        resolve(res)
      })
    })
  },
  /*  web端：只用手机号登录 */
  loginByPhoneOnly (params) {
    return new Promise((resolve, reject) => {
      Axios.post('login/loginByPhoneOnly', params).then(res => {
        resolve(res)
      }).catch((res) => {
        reject(res)
      })
    })
  },
  loginByUserId (params) {
    return new Promise((resolve, reject) => {
      Axios.post('login/loginByUserId', params, { isJSON: false }).then(res => {
        resolve(res)
      }).catch((res) => {
        reject(res)
      })
    })
  },
  // 燃气险情报警人登录
  gasCaseAlarmPersonLogin (params) {
    return new Promise((resolve, reject) => {
      Axios.post('login/gasCaseAlarmPersonLogin', params).then(res => {
        resolve(res)
      }).catch((res) => {
        reject(res)
      })
    })
  },
  // 领导手机端登录
  videoMeetingPhoneLogin (params) {
    return new Promise((resolve, reject) => {
      Axios.post('login/videoMeetingPhoneLogin', params, { isJSON: false }).then(res => {
        resolve(res)
      }).catch((res) => {
        reject(res)
      })
    })
  }
}
