// import HyRtcBusiness from 'hy-rtc-business'
import HyRtcBusiness from './business.js'
import { Message } from 'element-ui'
import store from '../store/index.js'

function install (Vue) {
  let sdk = null
  let form = {}
  let clientId = null
  let timeout = 0
  const business = {
    /**
     * 链接sdk
     */
    initSocket (res, callback) {
      let useSSL = true
      if (typeof res.useSSL === 'boolean') {
        useSSL = res.useSSL
      }
      const newSdk = new HyRtcBusiness({
        userId: res.userId,
        useSSL: useSSL,
        host: res.host,
        port: res.port - 0,
        appId: res.appId,
        path: res.path,
        clientId: res.clientId,
        userName: res.userName,
        password: res.password,
        heartbeatData: res.heartbeatData || null,
      })
      form = res
      clientId = res.clientId
      newSdk.eventEmit('open', () => {
        sdk = newSdk
        if (callback) {
          callback()
        }
      })
      newSdk.eventEmit('close', (e) => {
        console.warn('连接失败回调事件', e)
      })
      newSdk.eventEmit('disconnected', (e) => {
        console.warn('business_disconnected', e)
        this.disconnectedFn()
      })
      newSdk.eventEmit('methodLogout', () => {
        /* 退出登录 */
        store.getters.methodLogoutFn()
      })
      newSdk.eventEmit('msgNotRead', (data) => {
        /*新消息提醒*/
        store.commit('userOperationCallback', {
          operationName: 'msgNotRead',
          data
        })
      })
      // * method：newCase (有新警情)
      newSdk.eventEmit('newCase', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'newCase',
          data
        })
      })
      // * method：childOrgDealCase （通知作战部门上级和主管部门上级人员）
      newSdk.eventEmit('childOrgDealCase', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'childOrgDealCase',
          data
        })
      })
      //  * method：apLocationPush (下级发起拉入上级部门邀请)
      newSdk.eventEmit('apLocationPush', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'apLocationPush',
          data
        })
      })
      //  * method：pcVideoMeetingInvite （报警人上报警情定位）
      newSdk.eventEmit('pcVideoMeetingInvite', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'pcVideoMeetingInvite',
          data
        })
      })
      // method：caseStatusChange （警情状态修改通知出动部门）
      newSdk.eventEmit('caseLocationChange', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'caseLocationChange',
          data
        })
      })
      // method：videoMeetingInvite （服务邀请用户加入视频会议）
      newSdk.eventEmit('videoMeetingInvite', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'videoMeetingInvite',
          data
        })
      })
      // method：kickOut （警情踢人mq）
      newSdk.eventEmit('kickOut', (data) => {
        store.commit('userOperationCallback', {
          operationName: 'kickOut',
          data
        })
      })
    },

    // 主动断开
    disconnectCompletely () {
      sdk.disconnectCompletely()
    },

    /* 连接状态 */
    connectionStatus () {
      return sdk !== null
    },

    // sdk 调用回调对象
    callCallbackObject (name, callback) {
      sdk.eventEmit(name, (res) => {
        callback(res)
      })
    },

    disconnectedFn () {
      timeout++
      if (timeout < 3) {
        this.initSocket(form)
      } else {
        Message.error('连接失败')
        /* 退出登录 */
        store.getters.methodLogoutFn()
      }
    },

    /* 获取订阅的设备列表 */
    getSubscribedDevicesList () {
      return sdk.getSubscribedDevicesList()
    },

    /* 批量取消订阅设备 */
    resetDeviceIdList (deviceList) {
      if (sdk) {
        sdk.resetDeviceIdList(deviceList || [])
      }
    },

    // 批量订阅设备状态()
    addSubscriptionDevice (device) {
      if (device) {
        sdk.addSubscriptionDevice(device)
      }
    },

    // 批量订阅设备状态()
    bulkSubscriptionDevice (deviceList) {
      if (sdk) {
        sdk.bulkSubscriptionDevice(deviceList)
      }
    },

    /* 移除场景中订阅的设备 */
    deleteSceneDevice (sceneName) {
      if (sdk) {
        sdk.deleteSceneDevice(sceneName)
      }
    },

    /* 设备指令 */
    equipmentDirective (obj, successCallback, failCallback) {
      /*  equipment 参数解释
      *   takePicture: 拍照
      *   videoRecord: 录像 （输入录像时长）
      *   startVideoRecord: 开始录像
      *   stopVideoRecord: 停止录像
      * */
      if (obj.equipment) {
        let json = {}
        if (obj.equipment === 'takePicture') {
          /* 拍照 */
          Message.success('指令发送成功')
          sdk.takePicture({
            clientId: obj.deviceId
          }, (res) => {
            json = {
              ...res,
              ...obj
            }
            store.commit('addDirectiveListFn', json)
            if (successCallback) {
              successCallback(json)
            }
          }).catch((res) => {
            json = {
              ...res,
              ...obj
            }
            if (res.errorCode && res.errorCode === 1001) {
              Message.error(res.errorReason)
            } else {
              json.errorReason = '设备正在忙碌中'
              Message.error('设备正在忙碌中，请稍后再试')
            }
            store.commit('addDirectiveListFn', json)
            if (failCallback) {
              failCallback(res)
            }
          })
        } else if (obj.equipment === 'videoRecord') {
          /* 录像 （输入录像时长） */
          Message.success('指令发送成功')
          sdk.videoRecord({
            clientId: obj.deviceId,
            duration: obj.duration
          }, (res) => {
            json = {
              ...res,
              ...obj
            }
            store.commit('addDirectiveListFn', json)
            if (successCallback) {
              successCallback(json)
            }
          }).catch((res) => {
            json = {
              ...res,
              ...obj
            }
            if (res.errorCode && res.errorCode === 1001) {
              Message.error(res.errorReason)
            } else {
              json.errorReason = '设备正在忙碌中'
              Message.error('设备正在忙碌中，请稍后再试')
            }
            store.commit('addDirectiveListFn', json)
            if (failCallback) {
              failCallback(res)
            }
          })
        } else if (obj.equipment === 'startVideoRecord') {
          /* 开始录像 */
          sdk.startVideoRecord({
            clientId: obj.deviceId,
            maxDuration: obj.maxDuration, // (录像最大时间，到达后自动结束)
          }, (res) => {
            json = {
              ...res,
              ...obj
            }
            store.commit('addDirectiveListFn', json)
            if (successCallback) {
              successCallback(json)
            }
          }).catch((res) => {
            json = {
              ...res,
              ...obj
            }
            if (res.errorCode && res.errorCode === 1001) {
              Message.error(res.errorReason)
            } else {
              json.errorReason = '设备正在忙碌中'
              Message.error('设备正在忙碌中，请稍后再试')
            }
            store.commit('addDirectiveListFn', json)
            if (failCallback) {
              failCallback(res)
            }
          })
        } else  if (obj.equipment === 'stopVideoRecord') {
          /* 停止录像 */
          /*
           * 状态：0准备中,1录像中,2录像成功,3录像失败,4已上传
           * */
          sdk.stopVideoRecord({
            clientId: obj.deviceId,
            key: obj.key
          }, (res) => {
            json = {
              ...res,
              ...obj
            }
            store.commit('addDirectiveListFn', json)
            if (successCallback) {
              successCallback(json)
            }
          }).catch((res) => {
            if (failCallback) {
              failCallback(res)
            }
          })
        }  else  if (obj.equipment === 'startAudioRecord') {
          /* 开始录音 */
          sdk.startAudioRecord({
            clientId: obj.deviceId,
            maxDuration: obj.maxDuration, // (录像最大时间，到达后自动结束)
          }, (res) => {
            json = {
              ...res,
              ...obj
            }
            store.commit('addDirectiveListFn', json)
            if (successCallback) {
              successCallback(json)
            }
          }).catch((res) => {
            json = {
              ...res,
              ...obj
            }
            if (res.errorCode && res.errorCode === 1001) {
              Message.error(res.errorReason)
            } else {
              json.errorReason = '设备正在忙碌中'
              Message.error('设备正在忙碌中，请稍后再试')
            }
            store.commit('addDirectiveListFn', json)
            if (failCallback) {
              failCallback(res)
            }
          })
        }  else  if (obj.equipment === 'stopAudioRecord') {
          /* 停止录音 */
          /*
          * 状态：0准备中,1录像中,2录像成功,3录像失败,4已上传
          * */
          sdk.stopAudioRecord({
            clientId: obj.deviceId,
            key: obj.key
          }, (res) => {
            json = {
              ...res,
              ...obj
            }
            store.commit('addDirectiveListFn', json)
            if (successCallback) {
              successCallback(json)
            }
          }).catch((res) => {
            if (failCallback) {
              failCallback(res)
            }
          })
        }
      }
    },
    /* 查询⽇期列表 */
    deviceQueryFileDates (obj, successCallback, failCallback) {
      if (sdk) {
        sdk.queryFileDates(obj, (res) => {
        if (successCallback) {
          successCallback(res)
        }
      }).catch((res) => {
        if (failCallback) {
          failCallback(res)
        }
      })
      }
    },

    /* 查询⽂件列表 */
    deviceQueryFiles (obj, successCallback, failCallback) {
      if (sdk) {
        sdk.queryFiles(obj, (res) => {
        if (successCallback) {
          successCallback(res)
        }
      }).catch((res) => {
        if (failCallback) {
          failCallback(res)
        }
      })
      }
    },

    /* 设备文件上传 */
    deviceUploadFile (obj, successCallback, failCallback) {
      if (sdk) {
        sdk.uploadFile({
          clientId: obj.deviceId,
          fileId: obj.fileId
        }, (res) => {
          if (successCallback) {
            successCallback(res)
          }
        }).catch((res) => {
          if (failCallback) {
            failCallback(res)
          }
        })
      }
    },

    /* 聊天新增订阅 */
    chatSubscribe (roomId, chatMessageCallback) {
      if (sdk) {
        sdk.chatSubscribe(roomId, chatMessageCallback)
      }
    },

    /* 聊天取消订阅 */
    chatUnsubscribe (roomId) {
      if (sdk) {
        sdk.chatUnsubscribe(roomId)
      }
    },


    /* 视频会议新增订阅 */
    videoMeetingSubscribe (roomId, chatMessageCallback) {
      if (sdk) {
        sdk.videoMeetingSubscribe(roomId, chatMessageCallback)
      }
    },

    /* 视频会议取消订阅 */
    videoMeetingUnsubscribe (roomId) {
      if (sdk) {
        sdk.videoMeetingUnsubscribe(roomId)
      }
    },


    /* 画板新增订阅 */
    canvasSubscribe (roomId, chatMessageCallback) {
      if (sdk) {
        sdk.canvasSubscribe(roomId, chatMessageCallback)
      }
    },

    /* 画板取消订阅 */
    canvasUnsubscribe (roomId) {
      if (sdk) {
        sdk.canvasUnsubscribe(roomId)
      }
    },

    /* 警情新增订阅 */
    gasCaseSubscribe(caseUniqueId, chatMessageCallback) {
      if (sdk) {
        sdk.gasCaseSubscribe(caseUniqueId, chatMessageCallback)
      }
    },

    /* 警情会议取消订阅 */
    gasCaseUnsubscribe(caseUniqueId) {
      if (sdk) {
        sdk.gasCaseUnsubscribe(caseUniqueId)
      }
    },

    /* 根据场景订阅的设备
    *  sceneName (场景名称)
    *  sceneNameCallback （如果发现场景名称重复或者没有，都会自动生成一个名称回调）
    *  callback （设备改变回调）
    *  */
    addSceneDevice (obj) {
      if (sdk) {
        sdk.addSceneDevice(obj)
      }
    }

  }
  Vue.prototype.$business = Object.assign({}, business)
}
export default install
