import Axios from '@/http'

export default {
  /* 画板绘画 */
  videoMeetingCanvas (params) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/canvas/draw', params, { isJSON: true }).then(res => {
        return resolve(res)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  /* 视频会议录制房间开始or结束（开始录制or结束录制） */
  recordRoomOperate (params) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/recordRoomOperate', params).then(res => {
        return resolve(res)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  /* 创建视频会议录制房间 */
  recordRoomCreate (uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/recordRoomCreate', { uniqueId }).then(res => {
        return resolve(res)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  /* 共享屏幕or共享画板 通知 */
  videoMeetingShare (params) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/share', params).then(res => {
        return resolve(res)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  /* 结束会议 */
  videoMeetingEnd (uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/end', { uniqueId }).then(res => {
        return resolve(res)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  /* 内部加入会议 */
  videoMeetingIn (uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/videoMeetingIn', { uniqueId }).then(res => {
        return resolve(res)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  /* 获取会议详情 */
  selectByUniqueId (uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/selectByUniqueId', { uniqueId }).then(res => {
        return resolve(res)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  /* 视频会议成员-正在房间内的 */
  roomMemberFindAll (uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/member/roomMemberFindAll', { uniqueId }).then(res => {
        return resolve(res)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  /* 需要打开监控的设备 */
  getMonitorDevice (uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/getMonitorDevice', { uniqueId }).then(res => {
        return resolve(res)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  /* 创建视频会议新标签页获取mq连接信息校验码 */
  validCodeCreate (uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/validCode/create', { uniqueId }).then(res => {
        return resolve(res)
      })
    })
  },
  /* 发起禁言or取消禁言 */
  videoMeetingMuted (params) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/muted', params).then(res => {
        return resolve(res)
      })
    })
  },
  /* 查询禁言状态(1禁言、2未禁言) */
  videoMeetingGetMuted (uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/getMuted', { uniqueId }).then(res => {
        return resolve(res)
      })
    })
  },
  /* 视频会议成员客户端信息 */
  clientInfo (params) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/member/clientInfo', params).then(res => {
        resolve(res)
      })
    })
  },
  /* 主持人在视频会议的设备区对设备发起监控or关闭监控 */
  deviceMonitor (params) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/deviceMonitor', params).then(res => {
        resolve(res)
      })
    })
  },
  /* 将成员踢出视频会议 */
  memberKickOut (params) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/memberKickOut', params, { isJSON: true }).then(res => {
        resolve(res)
      })
    })
  },
  /* 邀请成员加入 */
  inviteMember (params) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/inviteMember', params, { isJSON: true }).then(res => {
        resolve(res)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  /* 更换主持人 */
  hostUserChange (params) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/hostUserChange', params).then(res => {
        resolve(res)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  /* 手动邀请设备 */
  deviceVideoMeetingInvite (params) {
    return new Promise((resolve, reject) => {
      Axios.post('test/deviceVideoMeetingInvite', params).then(res => {
        return resolve(res)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}
