/**
 * 全局统一的增删改查请求
 * 说明：自带增删改查
 * 如果有特殊的api 就在modules中新增 并且引用 全局中使用 this.$api 进行引用
 * */

import Axios from '@/http'
import Element from 'element-ui'
// import serverUpload from '@utils/serverUpload'
// 自动导入api模块
const modulesFiles = require.context('./modules', false, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

function install (Vue) {
  Vue.prototype.$api = {
    select (controller, params) {
      return new Promise((resolve, reject) => {
        if (!params) {
          return reject(new Error('未传入ID'))
        }
        Axios.post(controller + '/select', { id: params }).then(res => {
          resolve(res)
        }).catch(res => {
          reject(new Error('接口报错'))
        })
      })
    },
    detail (controller, params) {
      return new Promise((resolve, reject) => {
        if (!params) {
          return reject(new Error('未传入ID'))
        }
        Axios.post(controller + '/detail', { id: params }).then(res => {
          resolve(res)
        }).catch(res => {
          reject(new Error('接口报错'))
        })
      })
    },
    insertFn (controller, params) {
      for (const obj in params) {
        if (obj.indexOf('createDtm') > -1 || obj.indexOf('updateDtm') > -1) {
          delete params[obj]
        } else if (params[obj] === null) {
          // 移除null字段
          delete params[obj]
        }
      }
      const url = controller + '/insert'
      return new Promise((resolve, reject) => {
        Axios.post(url, params, { isJSON: true }).then(res => {
          return resolve(res)
        })
      })
    },
    save (controller, params) {
      for (const obj in params) {
        if (obj.indexOf('createDtm') > -1 || obj.indexOf('updateDtm') > -1) {
          delete params[obj]
        } else if (params[obj] === null) {
          // 移除null字段
          delete params[obj]
        }
      }
      let url = ''
      if (params.id) {
        url = controller + '/update'
      } else {
        url = controller + '/insert'
      }
      return new Promise((resolve, reject) => {
        Axios.post(url, params, { isJSON: true }).then(res => {
          return resolve(res)
        })
      })
    },
    findAll (controller, params, isJSON) {
      return new Promise((resolve, reject) => {
        Axios.post(controller + '/findAll', params, { isJSON }).then(res => {
          resolve(res)
        })
      })
    },
    page (controller, params) {
      return new Promise((resolve, reject) => {
        Axios.post(controller + '/page', params).then(res => {
          resolve(res)
        })
      })
    },
    delete (controller, params) {
      return new Promise((resolve, reject) => {
        if (!params) {
          return reject(new Error('未传入ID'))
        }
        Axios.post(controller + '/delete', { id: params }).then(res => {
          Element.Message({
            type: 'success',
            message: '删除成功!'
          })
          resolve(res)
        })
      })
    },
    deleteBatch (controller, ids) {
      return new Promise((resolve, reject) => {
        if (!ids) {
          return reject(new Error('未传入ID'))
        }
        Axios.post(controller + '/deleteBatch', { ids }).then(res => {
          Element.Message({
            type: 'success',
            message: '删除成功!'
          })
          resolve(res)
        })
      })
    },
    displayChange (controller, ids, display) {
      return new Promise(resolve => {
        Axios.post(controller + '/displayChange', {
          ids: ids.toString(),
          display
        }).then(() => {
          resolve()
        })
      })
    },
    ...modules
  }
}

export default {
  install,
  modules
}
