import Axios from '@/http'

export default {
  getGuestToken (params) {
    return new Promise((resolve, reject) => {
      Axios.post('seller/getGuestToken', params, { isJSON: false }).then(res => {
        resolve(res)
      })
    })
  },
  getBuyerUrl (params) {
    return new Promise((resolve, reject) => {
      Axios.post('seller/getBuyerUrl', params, { isJSON: true }).then(res => {
        resolve(res)
      })
    })
  }
}
