<template>
  <svg :class="svgClass" aria-hidden="true" :svgName="svgName">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
  export default {
    name: 'SvgIcon',
    props: {
      svgName: {
        type: String,
        required: true
      },
      className: {
        type: String,
        default: ''
      }
    },
    computed: {
      // eslint-disable-next-line vue/return-in-computed-property
      clickFn () {
        this.$emit('click')
      },
      iconName () {
        return `#icon-${this.svgName}`
      },
      svgClass () {
        if (this.className) {
          return 'svg-icon ' + this.className
        } else {
          return 'svg-icon'
        }
      }
    }
  }
</script>
<style scoped lang="less" rel="stylesheet/less">
  svg + span {
    margin-left: 6px;
  }
  .svg-icon {
    width: 16px;
    height: 16px;
    vertical-align: -0.15em;
    stroke: currentColor;
    overflow: hidden;
    fill:currentColor;
    outline: none;
  }
</style>
