import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import sdk from '@utils/hyRtcSdk'
import business from '@utils/hyRtcBusiness'
import inputCheck from '@utils/inputCheck.js'
import api from './api'
import 'amfe-flexible'
import SvgIcon from './components/common/svgIcon.vue'
import './styles/basics_dispatch.less'
import './styles/tailwindcss.css'
//  elementUl 按需引入
import ElementUI from 'element-ui'

import Video from 'video.js'
import videoZhCN from 'video.js/dist/lang/zh-CN.json'
import './styles/video-js.css'
import './styles/element-ui.css'

Video.addLanguage('zh-CN', videoZhCN)
Vue.prototype.$video = Video // 引入Video播放器

Vue.use(ElementUI)
Vue.component('svg-icon', SvgIcon)
Vue.use(inputCheck)
Vue.use(sdk)
Vue.use(business)
Vue.config.productionTip = false
Vue.use(api)

// 文件路径 是否有子级 校验
const req = require.context('@/assets/svg', true, /\.svg$/)
const requireAll = requireContent => requireContent.keys().map(requireContent)
requireAll(req)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
