<template>
  <div
    v-loading="globalLoading"
    :element-loading-text="elementLoadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    id="app">
    <div id="intercomAudioBox" class="fixed opacity-0 overflow-hidden -top-10 -left-10 w-0 h-0"></div>
    <router-view/>
  </div>
</template>
<script type="text/ecmascript-6">
  import { mapGetters } from 'vuex'
  export default {
    name: 'App',
    computed: {
      ...mapGetters(['globalLoading'])
    },
    data () {
      return {
        elementLoadingText: '拼命加载中'
      }
    },
    destroyed () {
      this.$api.findAll('storeHouse')
    },
    created () {
      document.title = '指挥执法应用'
    },
    mounted () {
      // this.$message({
      //   showClose: true,
      //   duration: 0,
      //   message: '这是一条消息提示',
      //   type: 'success'
      // })
    },
    methods: {
    }
  }
</script>
<style lang="less">
</style>
