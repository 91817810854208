import Axios from '@/http'

export default {
  /* 通知警情状态 */
  roomMemberInOutNotice (params) {
    return new Promise((resolve, reject) => {
      Axios.post('media/roomMemberInOutNotice', params).then(res => {
        resolve(res)
      })
    })
  },
  /* 踢出房间 */
  exitRoom (roomId) {
    return new Promise((resolve, reject) => {
      Axios.post('media/exitRoom', { roomId }, { isJSON: true }).then(res => {
        resolve(res)
      })
    })
  },
  /* 加入警情视频会议 */
  caseVideoMeetingIn (caseId) {
    return new Promise((resolve, reject) => {
      Axios.post('media/caseVideoMeetingIn', { caseId }, { isJSON: false }).then(res => {
        resolve(res)
      })
    })
  }
}
