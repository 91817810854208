import Axios from '@/http'
export default {
  /* 加入聊天室 */
  chatJoin (roomId) {
    return new Promise((resolve, reject) => {
      Axios.post('chat/join', { roomId }).then(res => {
        resolve(res)
      })
    })
  },
  /* 发送群聊消息 */
  chatSend (params) {
    return new Promise((resolve, reject) => {
      Axios.post('chat/send', params, { isJSON: true }).then(res => {
        resolve(res)
      })
    })
  }
}
