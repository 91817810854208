const state = {
  administrationList: [],
  userInfo: window.sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {},
  AEPLogin: window.sessionStorage.getItem('AEPLogin') ? JSON.parse(sessionStorage.getItem('AEPLogin')) : null,
  /* 对方用户操作回调 */
  userOperationCallbackList: [],
  globalLoading: true
}

const getters = {
  administrationList (state) {
    return [...state.administrationList]
  },
  globalLoading (state) {
    return state.globalLoading
  },
  userInfo (state) {
    return state.userInfo
  },
  isLogin (state) {
    return state.userInfo.id
  },
  userToken (state) {
    return state.userInfo.token || ''
  },
  AEPLogin (state) {
    return state.AEPLogin
  }
}
const mutations = {
  userOperationCallback (state, obj) {
    state.userOperationCallbackList.forEach(v => {
      if (v.operationName === obj.operationName && v.callback) {
        v.callback(obj.data)
      }
    })
  },
  deleteUserOperationCallback (state, id) {
    const index = state.userOperationCallbackList.findIndex(v => v.id === id)
    if (index !== -1) {
      state.userOperationCallbackList.splice(index, 1)
    }
  },
  addUserOperationCallback (state, obj) {
    const index = state.userOperationCallbackList.findIndex(v => v.id === obj.id)
    if (index === -1) {
      state.userOperationCallbackList.push(obj)
    } else {
      state.userOperationCallbackList[index] = obj
    }
  },
  globalLoadingFn (state, type) {
    state.globalLoading = type
  },
  administrationListFn (state, administrationList) {
    state.administrationList = administrationList
  },
  setUserInfo (state, payload) {
    state.userInfo = payload
    window.sessionStorage.setItem('userInfo', JSON.stringify(payload))
  },
  logout () {
    state.userInfo = {}
    window.sessionStorage.setItem('userInfo', '')
    window.sessionStorage.setItem('permissionList', '')
    window.sessionStorage.clear('firstLogin')
    location.reload()
  },
  setAEPLogin (state, payload) {
    state.AEPLogin = payload
    window.sessionStorage.setItem('AEPLogin', JSON.stringify(payload))
  }
}

const actions = {
}

export default {
  state,
  getters,
  mutations,
  actions
}
